import React from 'react'
import OpenModal from 'src/components/OpenModal'

import { DataLayerProps } from 'src/hooks/useDataLayer/types'
import { Button, HowToBeInterBlack } from './style'

const ComoSerInterBlack = ({ sendDatalayerEvent }: DataLayerProps) => {
  return (
    <HowToBeInterBlack className='py-5 d-flex align-items-end align-items-lg-center'>
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-between'>
          <div className='col-12 col-lg-7 offset-lg-5'>
            <h2 className='fs-32 lh-40 fs-lg-36 lh-lg-45 text-white text-md-center text-lg-left fw-600 font-sora'>
              Como ser cliente Inter Black
            </h2>

            <p className='fs-16 lh-28 fs-md-20 lh-md-28 fs-lg-20 lh-lg-19 text-white text-md-center text-lg-left fw-300'>
              Evolua seu perfil de relacionamento com uma carteira de investimentos a partir de R$250 mil, seja com novos aportes ou aqueles que estão em outros bancos e corretoras.
            </p>

            <OpenModal
              to='modal-open-inter-black-contact-form'
              className='d-flex justify-content-md-center justify-content-lg-start'
            >
              <Button
                aria-label='Quero ser Inter Black'
                className='btn btn-orange--extra rounded-2 fs-14 fw-600 text-none mt-3'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_02',
                    section_name: 'Como ser Inter Black',
                    element_action: 'click button',
                    element_name: 'Quero ser Inter Black',
                  })
                }}
              >
                Quero ser Inter Black
              </Button>
            </OpenModal>
          </div>
        </div>
      </div>
    </HowToBeInterBlack>
  )
}

export default ComoSerInterBlack
