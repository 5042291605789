import React from 'react'
import OpenModal from 'src/components/OpenModal'
import { DataLayerProps } from 'src/hooks/useDataLayer/types'
import BeneficiosJSON from '../../assets/data/Beneficios.json'
import CardContent from './_CardContent'

import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import useWidth from 'src/hooks/window/useWidth'

import { WIDTH_MD } from 'src/styles/breakpoints'
import { Button, ButtonMobile, Section } from './style'

type BeneficiosProps = {
  title: string;
  description: string;
  image: string;
}

const Beneficios = ({ sendDatalayerEvent }: DataLayerProps) => {
  const windowWidth = useWidth(300)

  return (
    <Section className='py-5' id='beneficios'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-32 fs-lg-36 lh-40 lh-lg-45 text-white text-md-center'>
              Benefícios de ser cliente <span className='text-orange--extra text-md-center'>Inter Black</span>
            </h2>
            <p className='fs-18 fs-md-20 fs-lg-24 lh-28 lh-lh-28 text-white text-md-center fw-300'><span className='d-md-block d-lg-inline'>Uma conta completa e experiência exclusiva</span> do jeito que você merece.</p>
          </div>
        </div>
      </div>
      {windowWidth < WIDTH_MD &&
        <div className='mt-3'>
          {BeneficiosJSON.map((item: BeneficiosProps, index: number) => (
            <CardContent item={item} index={index} key={item.title} />
          ))}
          <OpenModal
            to='modal-open-inter-black-contact-form'
            className='d-flex justify-content-md-center justify-content-lg-start'
          >
            <ButtonMobile
              aria-label='Quero benefícios exclusivos Inter Black'
              className='btn btn-orange--extra rounded-2 fs-14 fw-600 text-none mt-3 py-4 d-flex'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_03',
                  section_name: 'Benefícios de ser cliente Inter Black',
                  element_action: 'click button',
                  element_name: 'Quero benefícios exclusivos Inter Black',
                })
              } }
            >
              Quero benefícios exclusivos Inter Black
            </ButtonMobile>
          </OpenModal>
        </div>}
      {windowWidth >= WIDTH_MD &&
        <>
          <div className='container container-carousel'>
            <div className='row'>
              <div className='col-12 col-lg-12 offset-xl-0'>
                <DefaultCarousel
                  sm={{ items: 1, partialVisibilityGutter: 50 }}
                  md={{ items: 2, partialVisibilityGutter: 55 }}
                  lg={{ items: 2, partialVisibilityGutter: 77 }}
                  xl={{ items: 2, partialVisibilityGutter: 120 }}
                >
                  {BeneficiosJSON.map((item: BeneficiosProps, index: number) => (
                    <CardContent item={item} index={index} key={item.title} />
                ))}
                </DefaultCarousel>

              </div>
            </div>
          </div>
          <div className='d-flex justify-content-center mt-md-3'>
            <OpenModal
              to='modal-open-inter-black-contact-form'
              className='d-flex justify-content-md-center justify-content-lg-start'
            >
              <Button
                aria-label='Quero benefícios exclusivos Inter Black'
                className='btn btn-orange--extra rounded-2 fs-14 fw-600 text-none mt-3'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_03',
                    section_name: 'Benefícios de ser cliente Inter Black',
                    element_action: 'click button',
                    element_name: 'Quero benefícios exclusivos Inter Black',
                  })
                }}
              >
                Quero benefícios exclusivos Inter Black
              </Button>
            </OpenModal>
          </div>
        </>
      }
    </Section>
  )
}

export default Beneficios
